import DATA from './../../plugins/DATA' 
import DB from './../../plugins/DB' 
import  { 
  toDateTime, 
  toDate,
  toTime,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0, 
  SIZE, 
} from './../../plugins/MBS_actions' 
import formatNumber  from './../../plugins/formatNumber'   
import WORKER from 'simple-web-worker'  

let NAME = "CALCULATE" 

const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength
 
export default{
    state: { 
      CALCULATING:[],   
      ITEMS:null, 
    },
    //___________________-GETTERS-_________________
    getters: { 
      isCalculating(state) { 
        return name=>{
          if (SIZE(state.CALCULATING)==0){return false}
          let join = state.CALCULATING.find(item=>{
            return TEXT_UP(item.name) == [TEXT_UP(name)]
          })
          return join?join.loading:false
        }
      }, 
      getCalculated(state) { 
        return name=>{
          if (SIZE(state.CALCULATING)==0){return null}
          let joins = state.CALCULATING.find(join=>{
            return TEXT_UP(join.name) == [TEXT_UP(name)]
          })
          return joins
        }
      },  
      getCalculateState(state) { 
        return state
      }, 
    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      setCalculating(state, payload) {
        let name = payload?payload.name:null
        let loading = payload?payload.loading:false
        let reload = payload?payload.reload:false
        let response = payload?payload.response:null
        if(!name){return}
        if (!state.CALCULATING) {
          state.CALCULATING = []
        }
        let joining_item = state.CALCULATING.find(item=>{
          return item.name == name
        })
        if (joining_item) {
          joining_item.loading = loading
          joining_item.reload = reload
          joining_item.response = response
        }else{
          state.CALCULATING.push({
            name:name,
            loading:loading,
            reload:reload,
            response:response,
          })
        } 
      },   
      setCalculatedItems(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null
          state[name] = data  
        } catch (error) {
          console.log(error);
        }
      }, 
    },
    //____________________-ACTIONS-_____________________
    actions: { 
      //-------------[CALCULATE]--------------- 
      calculate_stock({ commit},payload) {

        let response = {
          from:'calculate_stock',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          let name = TEXT_UP(DATA.ITEMS.STOCK_ORDERS.values)  
          let orders = payload?payload.data:null  
          let add = payload?payload.add:null 
          try { 
            if (!orders) {
              
            }    
            commit("setCalculating",{name:name,loading:true})
            if(orders){
              //opening
              let opening_stocks = []
              let all_opening_stocks = []
              let archived_opening_stocks = []

              let opening_stock_items = []
              let all_opening_stock_items = []
              let archived_opening_stock_items = []
              
              //received
              let received_stocks = []
              let all_received_stocks = []
              let archived_received_stocks = []

              let received_stock_items = []
              let all_received_stock_items = []
              let archived_received_stock_items = []
              
              //returned
              let returned_stocks = []
              let all_returned_stocks = []
              let archived_returned_stocks = []

              let returned_stock_items = []
              let all_returned_stock_items = []
              let archived_returned_stock_items = []
              
              //adjust
              let adjusted_stocks = []
              let all_adjusted_stocks = []
              let archived_adjusted_stocks = []

              let adjusted_stock_items = []
              let all_adjusted_stock_items = []
              let archived_adjusted_stock_items = []

              orders.forEach(order => {
                  //opening
                  if (order.order_type == DATA.ITEMS.OPENING_STOCK.value) { 
                      // save
                      all_opening_stocks.push(order)
                      if (order.archive) {
                          archived_opening_stocks.push(order)
                      } else {
                          opening_stocks.push(order)
                      }  

                      //get items
                      if (order.items) {
                          order.items.forEach(item => {
                              let s_item = {
                                  ...item,
                                  order_key:order.key,
                                  archive_order:order.archive?true:false
                              }
                              all_opening_stock_items.push(s_item)
                              if (order.archive) {
                                  archived_opening_stock_items.push(s_item)
                              } else {
                                  opening_stock_items.push(s_item)
                              }  
                          });
                      }
                  } 
                  
                  //Received
                  if (order.order_type == DATA.ITEMS.STOCK_RECEIVES.value) { 
                      // save
                      all_received_stocks.push(order)
                      if (order.archive) {
                          archived_received_stocks.push(order)
                      } else {
                          received_stocks.push(order)
                      }  

                      //get items
                      if (order.items) {
                          order.items.forEach(item => {
                              let s_item = {
                                  ...item,
                                  order_key:order.key,
                                  archive_order:order.archive?true:false
                              }
                              all_received_stock_items.push(s_item)
                              if (order.archive) {
                                  archived_received_stock_items.push(s_item)
                              } else {
                                  received_stock_items.push(s_item)
                              }  
                          });
                      }
                  } 
                  
                  //Returned
                  if (order.order_type == DATA.ITEMS.STOCKS_RETURN.value) { 
                      // save
                      all_returned_stocks.push(order)
                      if (order.archive) {
                          archived_returned_stocks.push(order)
                      } else {
                          returned_stocks.push(order)
                      }  

                      //get items
                      if (order.items) {
                          order.items.forEach(item => {
                              let s_item = {
                                  ...item,
                                  order_key:order.key,
                                  archive_order:order.archive?true:false
                              }
                              all_returned_stock_items.push(s_item)
                              if (order.archive) {
                                  archived_returned_stock_items.push(s_item)
                              } else {
                                  returned_stock_items.push(s_item)
                              }  
                          });
                      }
                  } 
                  
                  //Adjust
                  if (order.order_type == DATA.ITEMS.STOCK_ADJUSTMENTS.value) { 
                      // save
                      all_adjusted_stocks.push(order)
                      if (order.archive) {
                          archived_adjusted_stocks.push(order)
                      } else {
                          adjusted_stocks.push(order)
                      }  

                      //get items
                      if (order.items) {
                          order.items.forEach(item => {
                              let s_item = {
                                  ...item,
                                  order_key:order.key,
                                  archive_order:order.archive?true:false
                              }
                              all_adjusted_stock_items.push(s_item)
                              if (order.archive) {
                                  archived_adjusted_stock_items.push(s_item)
                              } else {
                                  adjusted_stock_items.push(s_item)
                              }  
                          });
                      }
                  } 
              }); 

              //SAVE
              if (add) {
                //opening 
                commit("setItems",{
                    name:DATA.ITEMS.OPENING_STOCK.values.toUpperCase(),
                    data:opening_stocks
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase(),
                    data:all_opening_stocks
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase(),
                    data:archived_opening_stocks
                })
                commit("setItems",{
                    name:DATA.ITEMS.OPENING_STOCK.values.toUpperCase()+"_ITEMS",
                    data:opening_stock_items
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase()+"_ITEMS",
                    data:all_opening_stock_items
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase()+"_ITEMS",
                    data:archived_opening_stock_items
                })
                
                //received 
                commit("setItems",{
                    name:DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase(),
                    data:received_stocks
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase(),
                    data:all_received_stocks
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase(),
                    data:archived_received_stocks
                }) 
                commit("setItems",{
                    name:DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase()+"_ITEMS",
                    data:received_stock_items
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase()+"_ITEMS",
                    data:all_received_stock_items
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase()+"_ITEMS",
                    data:archived_received_stock_items
                }) 
                
                //return 
                commit("setItems",{
                    name:DATA.ITEMS.STOCKS_RETURN.values.toUpperCase(),
                    data:returned_stocks
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase(),
                    data:all_returned_stocks
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase(),
                    data:archived_returned_stocks
                }) 
                commit("setItems",{
                    name:DATA.ITEMS.STOCKS_RETURN.values.toUpperCase()+"_ITEMS",
                    data:returned_stock_items
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase()+"_ITEMS",
                    data:all_returned_stock_items
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase()+"_ITEMS",
                    data:archived_returned_stock_items
                }) 
                
                //Adjusted 
                commit("setItems",{
                    name:DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase(),
                    data:adjusted_stocks
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase(),
                    data:all_adjusted_stocks
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase(),
                    data:archived_adjusted_stocks
                })  
                commit("setItems",{
                    name:DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase()+"_ITEMS",
                    data:adjusted_stock_items
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase()+"_ITEMS",
                    data:all_adjusted_stock_items
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase()+"_ITEMS",
                    data:archived_adjusted_stock_items
                })  
              } 
              response = {
                success:true, 
                data:{
                  //opening
                  opening_stocks:opening_stocks,
                  all_opening_stocks:all_opening_stocks,
                  archived_opening_stocks:archived_opening_stocks,
                  
                  opening_stock_items:opening_stock_items,
                  all_opening_stock_items:all_opening_stock_items,
                  archived_opening_stock_items:archived_opening_stock_items,

                  //received
                  received_stocks:received_stocks,
                  all_received_stocks:all_received_stocks,
                  archived_received_stocks:archived_received_stocks,

                  received_stock_items:received_stock_items,
                  all_received_stock_items:all_received_stock_items,
                  archived_received_stock_items:archived_received_stock_items,

                  //returned
                  returned_stocks:returned_stocks,
                  all_returned_stocks:all_returned_stocks,
                  archived_returned_stocks:archived_returned_stocks,

                  returned_stock_items:returned_stock_items,
                  returned_stock_items:all_returned_stock_items,
                  archived_returned_stock_items:archived_returned_stock_items,

                  //adjust
                  adjusted_stocks:adjusted_stocks,
                  all_adjusted_stocks:all_adjusted_stocks,
                  archived_adjusted_stocks:archived_adjusted_stocks,
                  
                  adjusted_stock_items:adjusted_stock_items,
                  all_adjusted_stock_items:all_adjusted_stock_items,
                  archived_adjusted_stock_items:archived_adjusted_stock_items, 
                }
              } 
            }else{
              response = {
                success:false, 
                data:null,
                error:'no data',
              }
            }  
            commit("setCalculating",{name:name,loading:false,response:response})
            resolve(response) 
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("setCalculating",{name:name,loading:false,response:response}) 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      calculate_sales({ commit},payload) {
        let response = {
          from:'calculate_sales',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          let name = TEXT_UP(+DATA.ITEMS.SALE_ORDERS.values)  
          let orders = payload?payload.data:null  
          let add = payload?payload.add:null 
          try { 
            if (!orders) { 
            }   
            commit("setCalculating",{name:name,loading:true}) 
            if(orders){
              //opening
              let sales = []
              let all_sales = []
              let archived_sales = []

              let sales_items = []
              let all_sales_items = []
              let archived_sales_items = [] 
              
              //returned
              let returned_sales = []
              let all_returned_sales = []
              let archived_returned_sales = []

              let returned_sales_items = []
              let all_returned_sales_items = []
              let archived_returned_sales_items = []
              
              //returned
              let un_returned_sales = []
              let all_un_returned_sales = []
              let archived_un_returned_sales = []

              let un_returned_sales_items = []
              let all_un_returned_sales_items = []
              let archived_un_returned_sales_items = []
              
              

              orders.forEach(order => {
                //all Sales
                // save
                all_sales.push(order)
                if (order.archive) {
                    archived_sales.push(order)
                } else {
                    sales.push(order)
                }  

                //get items
                if (order.items) {
                    order.items.forEach(item => {
                        let s_item = {
                            ...item,
                            order_key:order.key,
                            archive_order:order.archive?true:false
                        }
                        all_sales_items.push(s_item)
                        if (order.archive) {
                            archived_sales_items.push(s_item)
                        } else {
                            sales_items.push(s_item)
                        }  
                    });
                }


                //Returned
                if (order.order_type == DATA.ITEMS.SALES_RETURN.value) { 
                    // save
                    all_un_returned_sales.push(order)
                    if (order.archive) {
                        archived_un_returned_sales.push(order)
                    } else {
                        un_returned_sales.push(order)
                    }  

                    //get items
                    if (order.items) {
                        order.items.forEach(item => {
                            let s_item = {
                                ...item,
                                order_key:order.key,
                                archive_order:order.archive?true:false
                            }
                            all_un_returned_sales_items.push(s_item)
                            if (order.archive) {
                                archived_un_returned_sales_items.push(s_item)
                            } else {
                                un_returned_sales_items.push(s_item)
                            }  
                        });
                    }
                }else{

                }
                  
                  
              }); 

              //SAVE
              if (add) {   
                //All sales 
                commit("setItems",{
                    name:DATA.ITEMS.SALES.values.toUpperCase(),
                    data:sales
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.SALES.values.toUpperCase(),
                    data:all_sales
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.SALES.values.toUpperCase(),
                    data:archived_sales
                }) 
                commit("setItems",{
                    name:DATA.ITEMS.SALES.values.toUpperCase()+"_ITEMS",
                    data:sales_items
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.SALES.values.toUpperCase()+"_ITEMS",
                    data:all_sales_items
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.SALES.values.toUpperCase()+"_ITEMS",
                    data:archived_sales_items
                }) 
                
                //return 
                commit("setItems",{
                    name:DATA.ITEMS.SALES_RETURN.values.toUpperCase(),
                    data:returned_sales
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase(),
                    data:all_returned_sales
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase(),
                    data:archived_returned_sales
                }) 
                commit("setItems",{
                    name:DATA.ITEMS.SALES_RETURN.values.toUpperCase()+"_ITEMS",
                    data:returned_sales_items
                }) 
                commit("setItems",{
                    name:"ALL_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase()+"_ITEMS",
                    data:all_returned_sales_items
                })  
                commit("setItems",{
                    name:"ARCHIVED_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase()+"_ITEMS",
                    data:archived_returned_sales_items
                }) 
                
                //uN return 
                commit("setItems",{
                    name:"UN_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase(),
                    data:un_returned_sales
                }) 
                commit("setItems",{
                    name:"ALL_UN_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase(),
                    data:all_un_returned_sales
                })  
                commit("setItems",{
                    name:"ARCHIVED_UN_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase(),
                    data:archived_un_returned_sales
                }) 
                commit("setItems",{
                    name:"UN_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase()+"_ITEMS",
                    data:un_returned_sales_items
                }) 
                commit("setItems",{
                    name:"ALL_UN_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase()+"_ITEMS",
                    data:all_un_returned_sales_items
                })  
                commit("setItems",{
                    name:"ARCHIVED_UN_"+DATA.ITEMS.SALES_RETURN.values.toUpperCase()+"_ITEMS",
                    data:archived_un_returned_sales_items
                })    
              }  
              response = {
                success:true, 
                data:{
                  //Sales
                  sales:sales,
                  all_sales:all_sales,
                  archived_sales:archived_sales,
                  
                  sales_items:sales_items,
                  all_sales_items:all_sales_items,
                  archived_sales_items:archived_sales_items,

                 

                  //returned
                  returned_sales:returned_sales,
                  all_returned_sales:all_returned_sales,
                  archived_returned_sales:archived_returned_sales,

                  returned_sales_items:returned_sales_items,
                  all_returned_sales_items:all_returned_sales_items,
                  archived_returned_sales_items:archived_returned_sales_items,
                  
                  //Un returned
                  un_returned_sales:un_returned_sales,
                  all_un_returned_sales:all_un_returned_sales,
                  archived_un_returned_sales:archived_un_returned_sales,

                  un_returned_sales_items:un_returned_sales_items,
                  all_un_returned_sales_items:all_un_returned_sales_items,
                  archived_un_returned_sales_items:archived_un_returned_sales_items, 
                }
              }
            }else{
              response = {
                success:false, 
                data:null,
                error:'no data',
              }
            }  
            commit("setCalculating",{name:name,loading:false,response:response})
            resolve(response) 
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("setCalculating",{name:name,loading:false,response:response}) 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      Back_calculate_items({ commit,dispatch,getters},payload) {
        let response = {
          from:'calculate_items',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          let name = payload?payload.name:null  
          let data = payload?payload.data:null  
          let add = payload?payload.add:null   
          let all = payload?payload.all:null   
          let archived = payload?payload.archived:null   
          let item_name = DATA.ITEMS.ITEMS.values
          try {  
            if (! data) {
              let isJoining = getters.isJoining(TEXT_UP(DATA.ITEMS.ITEMS.values)) 
              if (isJoining) { 
                setTimeout(() => {
                  commit("calculate_items",{
                    ...payload,
                    reload:true
                  })
                }, 10); 
                return
              }
              data = getters.getAllJoinedItems  
            }   
            commit("setCalculating",{name:item_name,loading:true})
            setTimeout(async() => { 
              if (data) { 
                let isArray = Array.isArray(data)
                let processed_data = null
                if (isArray) {
                  let joined_items = []
                  let all_joined_items = []
                  let archived_joined_items = []
                  let NUMBER = 0 
                  for (const element of data) { 
                    NUMBER = NUMBER+1  
                    let res = await dispatch("calculate_item",{
                      name:name,
                      data:element,
                      add:add,
                      all:all,
                      archived:archived,
                      NUMBER:NUMBER,
                    })
                    let res_data = res?res.data:null 
                    if (res_data) { 
                      all_joined_items.push(res_data)  
                      if (res_data.archive) {
                        archived_joined_items.push(res_data)
                      }else{
                        joined_items.push(res_data)
                      }
                    }  
                  } 
                  //save
                  if (add) {
                    commit("setCalculatedItems",{
                      name:TEXT_UP(item_name),
                      data:joined_items,
                    })
                    commit("setCalculatedItems",{
                      name:TEXT_UP("all_"+item_name),
                      data:all_joined_items,
                    })
                    commit("setCalculatedItems",{
                      name:TEXT_UP("archived_"+item_name),
                      data:archived_joined_items,
                    })
                  }
                  processed_data = {
                    joined:joined_items,
                    all_joined:all_joined_items,
                    archived_joined:archived_joined_items,
                  } 
                } else {
                  let res = await dispatch("calculate_item",{
                    name:item_name,
                    data:data,
                    all:all,
                    archived:archived,
                    add:add 
                  })
                  processed_data = res?res.data:null
                }   
                response = {
                  success:true, 
                  data:processed_data,
                  is_array:isArray,
                  isArray:isArray,
                }
                commit("setCalculating",{name:item_name,loading:false,response:response})
                resolve(response) 
              } else {
                response={
                  ...response,
                  success:false,
                  error:'no data found',
                }
                commit("setCalculating",{name:item_name,loading:false,response:response})
                reject(response)
              }
            }, 1);
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            commit("setCalculating",{name:item_name,loading:false,response:response})
            reject(response)  
          }  
        })
      },
      calculate_items({ commit,dispatch,getters},payload) {
        let response = {
          from:'calculate_items',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          let name = payload?payload.name:null  
          let data = payload?payload.data:null  
          let add = payload?payload.add:null   
          let all = payload?payload.all:null   
          let archived = payload?payload.archived:null   
          let item_name = DATA.ITEMS.ITEMS.values
          try {

            let all_items = getters.getAllJoinedItems
            if (SIZE(all_items) === 0) { 
              reject({error:"no items"})
              return
            }
            
            let calculateLoader = getters.getCalculated(item_name)    
            let joinLoader = getters.getJoined(item_name)    
            let isCalculating = calculateLoader?calculateLoader.loading:false
            let isJoining = joinLoader?joinLoader.loading:false
            if (isCalculating||isJoining) {
              // console.log('c..:'+isCalculating,"j..:"+isJoining,"******"); 
              commit("setCalculating",{name:item_name,loading:isCalculating,reload:true}) 
              resolve({success:false,reload:true})
              return
            }  
            // console.log("****************STARTS******************");
            commit("setCalculating",{name:item_name,loading:true})
            setTimeout(() => {
              let item_state = getters.getItemsState
              let join_state = getters.getJoinState
              let calculate_state = getters.getCalculateState
              let state = {
                  items:item_state,
                  join:join_state,
                  calculate:calculate_state,
              } 
              const worker = new Worker("/workers/calculateItems.js", { type: 'module' });
              worker.postMessage({ 
                  states:state, 
                  all:all, 
                  archived:archived, 
                  action:"calculate_items"
              });
              worker.onmessage = async e => {
                response = e?e.data:null
                let data = response?response.data:null 
                let items = data?data.items:null 
                let all_items = data?data.all_items:null 
                let archived_items = data?data.archived_items:null 
                let calculateLoader = getters.getCalculated(item_name)  
                let reload = calculateLoader?calculateLoader.reload:false
                commit("setCalculating",{name:item_name,loading:false,response:response})
                if (reload) {
                  // console.log("************Reload*********");
                  try {
                    response = await dispatch("calculate_items",{...payload,reload:true})
                    // console.log("Reloaded success********");
                  } catch (error) {
                    // console.log("Reloaded ----failed--- ********");
                  } 
                }
                //save
                if (add) { 
                  commit("setCalculatedItems",{
                    name:TEXT_UP(item_name),
                    data:items,
                  })
                  commit("setCalculatedItems",{
                    name:TEXT_UP("all_"+item_name),
                    data:all_items,
                  })
                  commit("setCalculatedItems",{
                    name:TEXT_UP("archived_"+item_name),
                    data:archived_items,
                  })
                }
                
                resolve(response)
              };   
            }, 1); 
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            commit("setCalculating",{name:item_name,loading:false,response:response})
            reject(response)  
          }  
        })
      },
      calculate_item({ commit,getters,state},payload) {
        let response = {
          from:'calculate_item',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try { 
            let name = payload?payload.name:null  
            let joined_item = payload?payload.data:null  
            let add = payload?payload.add:null 
            let all = payload?payload.all:null 
            let archived = payload?payload.archived:null  
            if (!joined_item) {
              
            }   
            if(joined_item){
              let key = joined_item.key

              //Calculate Opening Stock Order items
              let opening_stock_items = getters.getOpeningStockItems({
                item_key:key,
                all:all,
                archived:archived,
              }) 
              let opening_stock_items_size = SIZE(opening_stock_items)
              let total_opening_cost_price = 0
              let total_opening_stock_quantity = 0
              if (opening_stock_items) { 
                for (const item of opening_stock_items) {
                  let cost_price = toNumber(item.price)
                  let discount = toNumber(item.discount)
                  let quantity = toNumber(item.quantity)
                  let cost = quantity*(cost_price-discount)
                  total_opening_cost_price = total_opening_cost_price+cost
                  total_opening_stock_quantity = total_opening_stock_quantity+quantity  
                } 
              }

              //Calculate Received Stock Order items
              let received_stock_items = getters.getReceivedStockItems({
                item_key:key,
                all:all,
                archived:archived,
              })  
              let received_stock_items_size = SIZE(received_stock_items)
              let total_received_cost_price = 0
              let total_received_stock_quantity = 0
              if (received_stock_items) {
                  received_stock_items.forEach(item => {
                      let cost_price = toNumber(item.price)
                      let discount = toNumber(item.discount)
                      let quantity = toNumber(item.quantity)
                      let cost = quantity*(cost_price-discount)
                      total_received_cost_price = total_received_cost_price+cost
                      total_received_stock_quantity = total_received_stock_quantity+quantity 
                  }); 
              }

              //Calculate Returned Stock Order items
              let returned_stock_items = getters.getReturnedStockItems({
                item_key:key,
                all:all,
                archived:archived,
              })  
              let returned_stock_items_size = SIZE(returned_stock_items)
              let total_returned_cost_price = 0
              let total_returned_stock_quantity = 0
              if (returned_stock_items) {
                  returned_stock_items.forEach(item => {
                      let cost_price = toNumber(item.price)
                      let discount = toNumber(item.discount)
                      let quantity = toNumber(item.quantity)
                      let cost = quantity*(cost_price-discount)
                      total_returned_cost_price = total_returned_cost_price+cost
                      total_returned_stock_quantity = total_returned_stock_quantity+quantity 
                  }); 
              }
              
              //Calculate Adjust Stock Order items
              let adjusted_stock_items = getters.getAdjustedStockItems({
                item_key:key,
                all:all,
                archived:archived,
              })  
              let adjusted_stock_items_size = SIZE(adjusted_stock_items)
              let total_adjust_cost_price = 0
              let total_adjust_stock_quantity = 0
              if (adjusted_stock_items) {
                  adjusted_stock_items.forEach(item => {
                      let cost_price = toNumber(item.price)
                      let discount = toNumber(item.discount)
                      let quantity = toNumber(item.quantity)
                      let cost = quantity*(cost_price-discount)
                      total_adjust_cost_price = total_adjust_cost_price+cost
                      total_adjust_stock_quantity = total_adjust_stock_quantity+quantity 
                  }); 
              }


              //calculate order items
              let sold_items = getters.getSoldItems({
                item_key:key,
                all:all,
                archived:archived,
              })    
              let sold_items_size = SIZE(sold_items)
              let total_sold_price = 0
              let total_sold_quantity = 0
              if (sold_items) {
                  sold_items.forEach(item => {
                      let sold_price =toNumber(item.sold_price)
                      let discount = toNumber(item.discount)
                      let quantity = toNumber(item.quantity)
                      let sold = quantity*(sold_price-discount)
                      total_sold_price = total_sold_price+sold
                      total_sold_quantity = total_sold_quantity+quantity 
                  }); 
              } 
              
              //Calculate Return Sold Stock Order items
              let return_sold_items = getters.getReturnedSoldItems({
                item_key:key,
                all:all,
                archived:archived,
              })  
              let return_sold_items_size = SIZE(return_sold_items)
              let total_return_sold_cost_price = 0
              let total_return_sold_stock_quantity = 0
              if (return_sold_items) {
                  return_sold_items.forEach(item => {
                      let sold_price = toNumber(item.sold_price)
                      let discount = toNumber(item.discount)
                      let quantity = toNumber(item.quantity)
                      let cost = quantity*(sold_price-discount)
                      total_return_sold_cost_price = total_return_sold_cost_price+cost
                      total_return_sold_stock_quantity = total_return_sold_stock_quantity+quantity 
                  }); 
              }

              //calculate average 
              let stock_order_items_size =  
                  received_stock_items_size
                  -returned_stock_items_size 
                  +adjusted_stock_items_size 
                  +opening_stock_items_size  
              let total_cost_price = 
                  total_received_cost_price  
                  -total_returned_cost_price 
                  +total_adjust_cost_price  
                  +total_opening_cost_price
              let total_stock_quantity = 
                  total_received_stock_quantity  
                  -total_returned_stock_quantity 
                  +total_adjust_stock_quantity  
                  +total_opening_stock_quantity
              
              let average_cost_price = total_cost_price/total_stock_quantity


              //calculate VAT
              let purchase_vat = 0
              let sold_vat = 0
              let payable_vat = 0
              let tax = joined_item.tax
              if (tax) {
                  purchase_vat = total_cost_price*(16.5)/100
                  sold_vat = total_sold_price*(16.5)/100
                  payable_vat = sold_vat-purchase_vat
              }

              let newItem = {
                ...joined_item, 

                purchase_vat:purchase_vat,
                purchase_vat_:toMoney(purchase_vat),
                sold_vat:sold_vat,
                sold_vat_:toMoney(sold_vat),
                payable_vat:payable_vat,
                payable_vat_:toMoney(payable_vat),
                
                //Sold
                sold_items:sold_items, 
                sold_items_size:sold_items_size, 
                total_sold_price:total_sold_price, 
                total_sold_price_:toMoney(total_sold_price), 
                total_sold_quantity:total_sold_quantity, 
                
                //Opening Sock
                opening_stock_items:opening_stock_items, 
                opening_stock_items_size:opening_stock_items_size, 
                total_opening_cost_price:total_opening_cost_price, 
                total_opening_cost_price_:toMoney(total_opening_cost_price), 
                total_opening_stock_quantity:total_opening_stock_quantity, 
                
                //Received Sock
                received_stock_items:received_stock_items, 
                received_stock_items_size:received_stock_items_size, 
                total_received_cost_price:total_received_cost_price, 
                total_received_cost_price_:toMoney(total_received_cost_price), 
                total_received_stock_quantity:total_received_stock_quantity, 
                
                //Returned Sock
                returned_stock_items:returned_stock_items, 
                returned_stock_items_size:returned_stock_items_size, 
                total_returned_cost_price:total_returned_cost_price, 
                total_returned_cost_price_:toMoney(total_returned_cost_price), 
                total_returned_stock_quantity:total_returned_stock_quantity, 
                
                //Adjust Sock
                adjusted_stock_items:adjusted_stock_items, 
                adjusted_stock_items_size:adjusted_stock_items_size, 
                total_adjust_cost_price:total_adjust_cost_price, 
                total_adjust_cost_price_:toMoney(total_adjust_cost_price), 
                total_adjust_stock_quantity:total_adjust_stock_quantity, 

                //Return sold Sock
                return_sold_items:return_sold_items, 
                return_sold_items_size:return_sold_items_size, 
                total_return_sold_cost_price:total_return_sold_cost_price, 
                total_return_sold_cost_price_:toMoney(total_adjust_cost_price), 
                total_return_sold_stock_quantity:total_return_sold_stock_quantity, 

                stock_order_items_size:stock_order_items_size, 
                total_cost_price:total_cost_price, 
                total_cost_price_:toMoney(total_cost_price), 
                total_stock_quantity:total_stock_quantity,  
                total_cost_quantity_:total_stock_quantity+"/"+stock_order_items_size,  
                average_cost_price:average_cost_price,  
                
                total_quantity:
                    total_stock_quantity
                    -total_sold_quantity
                    +total_return_sold_stock_quantity,  
                
                retail_price_:toMoney(joined_item?.retail_price), 
                wholesale_price_:toMoney(joined_item.wholesale_price), 
                average_cost_price_:toMoney(average_cost_price),  
                code_:toSameLength(joined_item.code),
                created_at_:toDateTime(joined_item.created_at), 
                created_at_server_:toDateTime(joined_item.created_at_server),
                updated_at_:toDateTime(joined_item.updated_at), 
                updated_at_server_:toDateTime(joined_item.updated_at_server), 
              } 
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
    }
}